<template>
  <div class="container">
    <el-row>
      <content-title :nav="nav"></content-title>
    </el-row>

    <el-row>
      <el-tabs v-model="activeTableName" @tab-click="listTab">
        <el-tab-pane label="账户列表" name="first">
          <el-form :inline="true" class="demo-form-inline">
            <!-- <el-form-item label="机构id">
                <el-input v-model="jigou_id"></el-input>
              </el-form-item> -->
            <el-form-item label="机构">
              <el-select filterable v-model="jigou_id" clearable remote :remote-method="AllOrgName" @focus="AllOrgName">
                <el-option v-for="(option, index) in mechanism_name_list" :key="index" :value="option.id"
                  :label="option.mechanism_name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="支付宝账号">
              <el-input v-model="alicount"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="search">查询</el-button>
              <el-button @click="addrech" type="primary">添加账户
              </el-button>
            </el-form-item>
          </el-form>

          <el-table :data="user_list" :fit="true" :cell-style="{ 'text-align': 'center' }" v-loading="is_loading"
            :header-cell-style="{ 'text-align': 'center' }">
            <el-table-column label="用户昵称">
              <template v-slot="{ row }">
                <link-to :to="{ name: 'userDetails', params: { id: row.user_id } }"> {{ row.nickName }}</link-to>
              </template>
            </el-table-column>
            <el-table-column label="实名" prop="name"></el-table-column>
            <el-table-column label="支付宝" prop="aliPay"> </el-table-column>
            <!-- <el-table-column label="微信" prop="wxPay"> </el-table-column> -->
            <el-table-column label="机构" prop="mechanismName">
              <template v-slot="{ row }">
                <link-to :to="{ name: 'mecDetails', query: { mecID: row.mechanism_id } }">
                  {{ row.mechanismName }}
                </link-to>
              </template>
            </el-table-column>
            <el-table-column label="操作" prop="">
              <template slot-scope="scope">
                <el-button size="small" @click="update(scope.row)" type="danger">修改
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <paging-fy @currentPageChange="payPageChange" :currentPage.sync="currentPage" :total="user_total"></paging-fy>
        </el-tab-pane>
      </el-tabs>
    </el-row>

    <el-dialog title="添加账户" :visible.sync="adddialogVisible" :width="kd ? '95%' : '30%'" :before-close="handleClose">
      <div>
        <el-form class="demo-form-inline" label-width="120px" label-position="left">

          <el-form-item label="支付宝姓名" required>
            <el-input v-model="name" placeholder="支付宝姓名"></el-input>
          </el-form-item>

          <el-form-item label="支付宝账号" required>
            <el-input v-model="ali" placeholder="支付宝账号"></el-input>
          </el-form-item>

          <el-form-item label="绑定机构">
            <el-select filterable v-model="mechanism_id" clearable remote :remote-method="searchOrgName">
              <el-option v-for="(option, index) in mechanism_name_list" :key="index" :value="option.id"
                :label="option.mechanism_name"></el-option>
            </el-select>
          </el-form-item>
             <el-form-item label="绑定用户" >
            <el-select v-model="selected_user" placeholder="请选择" value-key="user_id" filterable style="margin-top: 10px"
              remote :remote-method="getUser">
              <el-option v-for="item in userList" :key="item.user_id" :label="item.nick_name" :value="item.user_id">
              </el-option>
            </el-select>
          </el-form-item>

           <el-form-item label="绑定的管理员">
            <adminSelect :adminid.sync="admin_id" :info="admin_id"/>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="adddialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitadd">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
<script>
import adminSelect from '../../components/select/adminSelect.vue';
export default {
  components:{
    adminSelect
  },
  data() {
    return {
      type: "add",
      curid: "",
      adddialogVisible: false,
      channels: "teach_paypal ",
      nav: { firstNav: "机构管理中心", secondNav: "账户管理" },
      activeTableName: "first",
      currentPage: 1,
      is_loading: true,
      login_name: null,
      // 查询
      flowing_no: "",
      user_info: {},
      userVisiable: false,
      studycard_id: null,
      studycard_list: [],
      timeRange: null,
      mobile: null,
      nick_name: "",
      card_id: null,
      title: null,
      mechanism_id: null,
      mechanism_name_list: [],
      payTotal: { settleCash: 0, cashTotal: 0, noSettleCash: 0 },
      user_list: [],
      user_total: 0,
      selected_user: "",
      userList: [],
      ali: "",
      wx: "",
      name: "",
      jigou_id: "",
      alicount: "",
      admin_id:""
    };
  },
  methods: {
    getUser(query) {
      this.userList = []
      if (query && typeof query == 'string') {
        let url = '/user/userInfo/queryByMessage'
        this.$axios
          .get(url, {
            params: {
              mobile: query,
            },
          })
          .then((res) => {
            this.userList = res.data.data.rows;
          });
      }
    },
    // 机构查询远程查询
    AllOrgName(query) {
      let url = "/user/mastermechanism/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            mechanism_name:
              typeof query == "string" && query != "" ? query : null,
            pageSize: 10,
            currentPage: 1
          }
        })
        .then((res) => {
          // console.log(res);
          this.mechanism_name_list = res.data.data.rows;
        })
        .catch((err) => { });
    },
    // 机构查询远程查询
    searchOrgName(query) {
      let url = "/user/mastermechanism/queryByMessage";
      if (this.cancel) {
        this.cancel.abort();
      }
      this.cancel = new AbortController();
      this.$axios
        .get(url, {
          params: {
            mechanism_name:
              typeof query == "string" && query != "" ? query : null,
            pageSize: 10,
            currentPage: 1,
            status: 2,
          },
          signal: this.cancel.signal,
        })
        .then((res) => {
          // console.log(res);
          this.mechanism_name_list = res.data.data.rows;
        })
        .catch((err) => { });
    },
    addrech() {
      this.adddialogVisible = true
      this.type = 'add'
      this.selected_user = ""
      this.mechanism_id = ""
      this.ali = ""
      this.wx = ""
      this.name = ''
    },
    update(row) {
      this.adddialogVisible = true
      this.type = 'update'
      this.selected_user = row.user_id
      this.mechanism_id = row.mechanism_id
      this.ali = row.aliPay
      this.wx = row.wxPay
      this.curid = row.id
      this.name = row.name
      this.admin_id = row.admin_id
    },
    submitadd() {
      let url = "";
      let data = {
        user_id: this.selected_user,
        mechanism_id: this.mechanism_id,
        aliPay: this.ali,
        wxPay: this.wx,
        name: this.name,
        admin_id:this.admin_id || null
      };
      if (this.type == "add") {
        if (!this.name) {
          this.$message({ message: '请输入姓名' })
          return
        }

        url = "/user/rechargeInfo/insert";
      } else {
        url = "/user/rechargeInfo/update";
        data.id = this.curid;
      }
      this.$axios({
        url,
        method: "post",
        data,
      }).then((res) => {
        this.adddialogVisible = false;
        this.$message({ message: res.data.message });
        this.getUserList();
      });
    },
    getUserList() {
      this.is_loading = true;
      let url = "/user/rechargeInfo/queryByMessage";
      let data = {
        pageSize: 10,
        currentPage: this.currentPage
      };
      if (this.jigou_id != "") {
        data.mechanism_id = this.jigou_id
      }
      if (this.alicount != "") {
        data.aliPay = this.alicount
      }
      this.$axios
        .get(url, {
          params: {
            ...data,
          },
        })
        .then((res) => {
          this.user_list = res.data.data.rows;
          this.user_total = res.data.data.total;
          this.is_loading = false;
        });
    },
    search(status) {
      this.currentPage = 1;
      this.getUserList(status);
    },

    payPageChange(v) {
      this.currentPage = v;
      this.getUserList();
    },
    finPageChange(v) {
      this.currentPage = v;
      this.getUserList();
    },
  },
  mounted() {
    this.kd = window.screen.width < 786;
    this.getUserList();
  },
};
</script>
  
<style>
.payment.el-row .el-col {
  display: flex;
  height: 10rem;
  box-shadow: 0px 0px 1rem 0px rgba(0, 0, 0, 0.07);
  padding: 1rem 2.5rem;
}

.payment.el-row .el-col div {
  margin-right: 3rem;
}

.el-row.payment {
  margin-bottom: 3rem;
}

.el-row.payment .el-col div div:first-child {
  font-size: 3rem;
}

.el-row.payment .el-col div.used div:first-child {
  color: #4fc5ea;
}

.el-row.payment .el-col div.unused div:first-child {
  color: #f44c4c;
}

.el-row.payment .el-col div.total div:first-child {
  color: #6c6fbf;
}

.payment .used,
.payment .unused,
.payment .total {
  display: flex;
  height: inherit;
  flex-direction: column;
  align-content: center;
}

.main .el-input {
  width: inherit;
}

.search>*:not(:last-child) {
  margin-right: 1rem;
}
</style>
  